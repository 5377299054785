html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
body {
    /*background-image: url("/background.svg");*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

textarea,
select,
input,
button {
    outline: 0;
}